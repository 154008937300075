// UI colors:
$color-primary: #22a7f0;
$color-primary-light:#81cfe0;
$color-primary-dark:#4183d7;

//$background:#f8f8f8;
//$background-light:#f4f7fd;
$background:#fff;
$background-light:#f8f8f8;

// menu colors:
$color-menu-primary:#303248;
$color-menu-text:#fff;
$color-menu-text-dark:#a4a6b7;
$color-menu-indicator:#4a4d7559;

// Status colors
$color-off:#f03434;
$color-on:#26a65b;
$color-on-light:#31e97d;
$color-processing:#446cb3;
$color-pending:#f89406;


// Background color
$background-grey-light:#f7f7f7;
$background-dark:#1d2634;

// Text colors:
$text-primary:#777;
$text-white:#fff;

// Grid
$grid-width:114rem;
$gutter-vertical:1rem;
$gutter-horizontal:1rem;

// Divider
$divider-color-primary:rgba(#000,.1)