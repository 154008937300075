
.btn,
.btn:link
.btn:visited{
    width: 156px;
    font-size: 1.3rem;
    padding: 12px 45px;
    border-radius: 100px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background-color: $color-primary-dark;
    color: #fff;
    border: 1px solid $color-primary-dark;
    transition: all .3s;
    box-shadow: -2px 5px 20px rgba($color-primary-dark, .4);
    &:active{

    }
    &:hover{
         background-color: #fff;
         color:rgba($color-primary-dark, .6);
         border: 1px solid rgba($color-primary-dark, .2);
    }

    
}


.btn-squre,
.btn-squre:link,
.btn-squre:visited{
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 8rem;
    height: 4rem;
    font-size: 1.3rem;
    padding: 6px 22px;
    border-radius: 3px;
    box-shadow: 0px 1px 2px rgba(#000, .2);
    transition: .3s all;
    letter-spacing: 1.5px;
   
    &.medium{
        font-size: 1.4rem;
        letter-spacing: 1.3px;
        height: 5rem;
    }

    &.big{
        font-size: 1.6rem;
        letter-spacing: 1.3px;
        height: 6rem; 
    }
}



.btn-full,
.btn-full:link,
.btn-full:visited{
    background: $color-primary-dark;
    color:rgba($color: #fff, $alpha: 1.0);
    &:hover{
         background-color: rgba(#fff,1);
         color:rgba($color-primary-dark, .6); 
    }
    &:active{
        background-color: rgba($color-primary-light,.2);
    } 
}


.btn-ghost,
.btn-ghost:link,
.btn-ghost:visited{
    color:$color-primary-dark;
    &:active{
        background-color: rgba($color-primary-light,.2);
    }
}