.row{
    max-width: $grid-width;
    margin: 0 auto;

    
    &:not(:last-child){
        margin-bottom: $gutter-vertical;
    }

    @include clear-fix;

    [class^="col-"]{
         //background-color: azure;
         float: left;
         &:not(:last-child){
             margin-right:$gutter-horizontal;
         }
    }

    .col-1-of-2{
        width: calc((100% - #{$gutter-horizontal} )/2); 
    }
    .col-1-of-3{
        width: calc((100% - 2 * #{$gutter-horizontal} )/3); 
    }
    .col-1-of-4{
         width: calc((100% - 3 * #{$gutter-horizontal} )/4); 
    }

    .col-2-of-3{
         width: calc( (2*(100% - 2 * #{$gutter-horizontal} )/3) + #{$gutter-horizontal} );
    }

    .col-2-of-4{
        width: calc( (2*(100% - 3 * #{$gutter-horizontal} )/4) + #{$gutter-horizontal} );
    }

    .col-3-of-4{
         width: calc( (3*(100% - 3 * #{$gutter-horizontal} )/4) +  2* #{$gutter-horizontal} );
    }

    /*** 7th grading ***/

     $gutter: ($gutter-horizontal  * 6);
    .col-1-of-7{
        width: calc((100% - #{$gutter})/7);
    }
    .col-2-of-7{
        width: calc( ((100% - #{$gutter}) * 2/7) + #{$gutter-horizontal} );
    }
    .col-3-of-7{
        width: calc( ((100% - #{$gutter}) * 3/7) + #{$gutter-horizontal*2} );
    }
    .col-3-of-7{
        width: calc( ((100% - #{$gutter}) * 3/7) + #{$gutter-horizontal*2} );
    }
    .col-4-of-7{
        width: calc( ((100% - #{$gutter}) * 4/7) + #{$gutter-horizontal*3} );
    }
    .col-5-of-7{
        width: calc( ((100% - #{$gutter}) * 5/7) + #{$gutter-horizontal*4} );
    }
    .col-6-of-7{
        width: calc( ((100% - #{$gutter}) * 6/7) + #{$gutter-horizontal*5} );
    }
    
}