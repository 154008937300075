@keyframes moveInRightSpring{
    0%{
        transform: translateX(-40%);
    }
    40%{
        transform: translateX(-25%);
    }
    50%{
        transform: translateX(-30%);
    }
    60%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(0%);
    }
}


@keyframes hoverFocus{
    0%{
        //@extend .elevate-1;
         box-shadow: 3px 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
    50%{
        //@extend .elevate-2;
        box-shadow:5px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
       
    }
    80%{
        //@extend .elevate-4;
        box-shadow: 10px 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
      
       
    }
    90%{
       box-shadow: 7px 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    
    }
    100%{
         //@extend .elevate-1;
       box-shadow:5px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
     
    }
}

