.footer{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    line-height: 1.42857;
    font-weight: 300;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    background: #000000d9;
    text-align: center;
    padding: 15px;
    color: rgb(225, 225, 225);
    font-size: 1.1rem;
    margin-top: 10em;
}